import theme from '../../theme';
import { StyledA, StyledGatsbyLink } from '../Styled';

const NavbarLinks = () => {
  return (
    <>
      <StyledGatsbyLink activeStyle={styles.active} sx={styles.link} to="/">
        Personas
      </StyledGatsbyLink>
      <StyledGatsbyLink activeStyle={styles.active} sx={styles.link} to="/empresas/">
        Empresas
      </StyledGatsbyLink>
      <StyledGatsbyLink activeStyle={styles.active} sx={styles.link} to="/nosotros/">
        Nosotros
      </StyledGatsbyLink>
      <StyledGatsbyLink activeStyle={styles.active} sx={styles.link} to="/compliance/">
        Cumplimiento
      </StyledGatsbyLink>
      <StyledGatsbyLink
        activeStyle={styles.active}
        sx={[styles.link, styles.benefitsLink]}
        to="/beneficios/"
        partiallyActive
      >
        Beneficios
      </StyledGatsbyLink>

      <StyledGatsbyLink activeStyle={styles.active} sx={[styles.link, styles.apiLink]} to="/api/">
        API
      </StyledGatsbyLink>
      <StyledA
        sx={[styles.link, styles.helpLink]}
        target="_blank"
        href="https://help.currencybird.cl/es/knowledge"
      >
        Ayuda
      </StyledA>
    </>
  );
};

const styles = {
  active: {
    color: `#FAB234`,
    fontWeight: `bold`,
  },
  link: {
    textDecoration: `none`,
    display: `inline-block`,
    transition: `all 100ms ease-out`,
    whiteSpace: `nowrap`,
    position: `relative`,
    textAlign: `center`,
    font: `normal normal normal 14px Montserrat`,
    letterSpacing: `0px`,
    color: `#fff`,
    opacity: 1,
    width: `14.2%`,
    '&:hover': {
      color: `#FAB234`,
      fontWeight: `bold`,
    },
    [theme.breakpoints.down(1280)]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down(900)]: {
      padding: `20px 0`,
      fontSize: `10px`,
      display: `none`,
    },
  },
  benefitsLink: {
    [theme.breakpoints.up(768)]: {
      right: '-2%',
    },
  },
  apiLink: {
    [theme.breakpoints.up(768)]: {
      right: '0%',
    },
  },
  helpLink: {
    [theme.breakpoints.up(768)]: {
      paddingRight: '50px',
    },
  },
};

export default NavbarLinks;
