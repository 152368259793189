import { Grid, List, ListItemButton } from '@mui/material';

import IconLinkedIn from './../../images/icon_linkedin.svg';
import IconTwitter from './../../images/icon_twitter.svg';
import IconFacebook from './../../images/icon_facebook.svg';
import IconInstagram from './../../images/icon_instagram.svg';
import { StyledA, StyledGatsbyLink, StyledImg } from '../Styled';
type Props = {
  setOpen: (val: boolean) => void;
};
const AppMenu: React.FC<Props> = ({ setOpen }) => {
  const activeStyles = {
    color: `#FAB234`,
    fontWeight: `bold`,
  };

  function handleClick() {
    setOpen(false);
  }

  return (
    <List component="nav" sx={styles.appMenu} disablePadding>
      <StyledGatsbyLink onClick={handleClick} activeStyle={activeStyles} to="/" sx={styles.link}>
        <ListItemButton sx={styles.menuItem}>Personas</ListItemButton>
      </StyledGatsbyLink>
      <StyledGatsbyLink
        onClick={handleClick}
        activeStyle={activeStyles}
        to="/empresas"
        sx={styles.link}
      >
        <ListItemButton sx={styles.menuItem}>Empresas</ListItemButton>
      </StyledGatsbyLink>

      <StyledGatsbyLink
        onClick={handleClick}
        activeStyle={activeStyles}
        to="/nosotros"
        sx={styles.link}
      >
        <ListItemButton sx={styles.menuItem}>Nosotros</ListItemButton>
      </StyledGatsbyLink>

      <StyledGatsbyLink
        onClick={handleClick}
        activeStyle={activeStyles}
        to="/compliance"
        sx={styles.link}
      >
        <ListItemButton sx={styles.menuItem}>Cumplimiento</ListItemButton>
      </StyledGatsbyLink>

      <StyledGatsbyLink
        onClick={handleClick}
        activeStyle={activeStyles}
        to="/beneficios"
        sx={styles.link}
      >
        <ListItemButton sx={styles.menuItem}>Beneficios</ListItemButton>
      </StyledGatsbyLink>

      <StyledGatsbyLink onClick={handleClick} activeStyle={activeStyles} to="/api" sx={styles.link}>
        <ListItemButton sx={styles.menuItem}>API</ListItemButton>
      </StyledGatsbyLink>

      <StyledA
        sx={styles.link}
        id="HelpLink"
        target="_blank"
        href="https://help.currencybird.cl/es/knowledge"
      >
        <ListItemButton sx={styles.menuItem}>Ayuda</ListItemButton>
      </StyledA>

      <Grid item xs={12} sx={styles.iconGroup}>
        <StyledA
          target="_blank"
          href="https://www.linkedin.com/company/currencybird?originalSubdomain=cl"
        >
          <StyledImg sx={styles.img} src={IconLinkedIn} alt="SocialGroup" />
        </StyledA>
        <StyledA target="_blank" href="https://twitter.com/currency_bird?lang=es">
          <StyledImg sx={styles.img} src={IconTwitter} alt="IconTwitter" />
        </StyledA>
        <StyledA target="_blank" href="https://www.facebook.com/currencybird.cl/">
          <StyledImg sx={styles.img} src={IconFacebook} alt="IconFacebook" />
        </StyledA>
        <StyledA target="_blank" href="https://www.instagram.com/currencybird">
          <StyledImg sx={styles.img} src={IconInstagram} alt="IconInstagram" />
        </StyledA>
      </Grid>
    </List>
  );
};

const drawerWidth = `100%`;

const styles = {
  appMenu: {
    width: '100%',
    paddingLeft: `6%`,
    paddingRight: `6%`,
    marginBottom: '20px',
  },
  navList: {
    width: drawerWidth,
  },
  menuItem: {
    width: drawerWidth,
    justifyContent: `center`,
    borderBottom: `1px solid #3B7892`,
  },
  menuItemIcon: {
    color: '#97c05c',
  },
  link: {
    color: '#fff',
    textDecoration: 'none',
  },
  iconGroup: {
    marginTop: '20px',
    textAlign: `center`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: '20px',
  },
  img: {
    width: '20px',
    marginRight: '20',
  },
};

export default AppMenu;
