import NavBar from './NavBar';

const Header = () => {
  return (
    <header className="global-header">
      <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Montserrat:200,300,500,700&display=swap"
      />
      <NavBar />
    </header>
  );
};

export default Header;
