import { Theme, useMediaQuery } from '@mui/material';

interface HiddenProps {
  children: React.ReactNode;
  direction: 'up' | 'down';
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

const Hidden = ({ children, direction, size }: HiddenProps) => {
  const hidden =
    direction === 'down'
      ? useMediaQuery((theme: Theme) => theme.breakpoints.down(size))
      : useMediaQuery((theme: Theme) => theme.breakpoints.up(size));
  if (hidden) return null;
  return <>{children}</>;
};

export default Hidden;
