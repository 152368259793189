module.exports = [{
      plugin: require('../plugins/gatsby-plugin-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WWC6P68","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"CurrencyBird SpA","short_name":"CurrencyBird","start_url":"/","background_color":"#ffffff","theme_color":"#004f71","display":"minimal-ui","icon":"content/assets/faviconCB.png","prefer_related_applications":true,"related_applications":[{"platform":"play","url":"https://play.google.com/store/apps/details?id=cl.currencybird.cbmobileapp","id":"cl.currencybird.cbmobileapp"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1bf8e808d7012732ce6c992e07f3413d"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
