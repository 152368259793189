import { createTheme, responsiveFontSizes } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    p: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    p?: React.CSSProperties;
  }

  interface Palette {
    terciary: Palette['primary'];
    quaternary: Palette['primary'];
    lightBlueBackground: string;
    iceBlue: string;
  }

  interface PaletteOptions {
    terciary?: PaletteOptions['primary'];
    quaternary?: PaletteOptions['primary'];
    lightBlueBackground?: string;
    iceBlue?: string;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    p: true;
  }
}

// General styles of the theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#004f71',
    },
    secondary: {
      main: '#f8b133',
    },
    divider: '#0000001f',
    background: {
      paper: '#ffffff',
    },
    error: {
      main: '#e03b2f',
    },
    success: {
      main: '#30ba6a',
    },
    tonalOffset: 0.5,

    // Custom colors
    terciary: {
      main: '#36adac',
      light: '#deefef',
    },
    quaternary: {
      main: '#41faf8',
    },
    lightBlueBackground: '#e6edf1',
    iceBlue: '#e5edf1',
  },
  typography: {
    fontFamily: "'Montserrat'",
    h1: {
      fontSize: '4.5rem',
    },
    h2: {
      fontSize: '1.375rem',
    },
    h4: {
      fontSize: '1.125rem',
    },
    h5: {
      fontSize: '1.48rem',
    },
    p: {
      display: 'block',
      fontSize: '0.875rem',
      lineHeight: '1.43',
    },
  },
  shape: {
    borderRadius: 4,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 501,
      md: 769,
      lg: 1364,
      xl: 1921,
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            height: '54px',
          },
        },
      },
    },
  },
});

export default responsiveFontSizes(theme);
