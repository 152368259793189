import { Theme } from '@mui/material/styles';
import theme from '../../theme';

const styles = {
  footer: {
    minHeight: '540px',
    marginTop: 'auto',
    paddingBottom: '20px',
    paddingTop: '30px',
    backgroundColor: `#004F71`,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
  },
  container: (theme: Theme) => ({
    maxWidth: '1100px',
    paddingLeft: '24px',
    paddingRight: '24px',
    [theme.breakpoints.down('md')]: {
      paddingTop: '70px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '50px',
    },
    paddingTop: '50px',
    height: `100%`,
    [theme.breakpoints.down('md')]: {
      height: 'auto',
      display: 'block',
    },
  }),
  h3: (theme: Theme) => ({
    margin: 0,
    fontSize: 18,
    textAlign: `left`,
    color: `#fff`,
    fontFamily: `Montserrat`,
    fontWeight: `bold`,
    [theme.breakpoints.down('md')]: {
      textAlign: `center`,
    },
  }),
  lineSeparator: (theme: Theme) => ({
    height: '0.5px',
    backgroundColor: theme.palette.common.white,
  }),
  h4: {
    margin: 0,
    fontSize: 14,
    textAlign: `center`,
    color: `#fff`,
    fontFamily: `Montserrat`,
    fontWeight: `normal`,
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },
  icon: (theme: Theme) => ({
    marginBottom: `20px`,
    height: '50px',
    [theme.breakpoints.down('md')]: {
      width: '100px',
    },
  }),
  link: {
    textDecoration: `none`,
    display: `inline-block`,
    transition: `all 100ms ease-out`,
    whiteSpace: `nowrap`,
    position: `relative`,
    textAlign: `center`,
    font: `normal normal normal 14px Montserrat`,
    letterSpacing: `0px`,
    color: `#fff`,
    opacity: 1,
    width: `16.6%`,
    '&:hover': {
      color: `#FAB234`,
      fontWeight: `bold`,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
      display: `block`,
      fontWeight: 300,
      lineHeight: 1,
      width: `100%`,
    },
  },
  iconGroup: (theme: Theme) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',
    [theme.breakpoints.down('lg')]: {
      gap: '10px',
    },
    [theme.breakpoints.down('md')]: {
      gap: '20px',
    },
    '& img': {
      width: '20px',
    },
  }),
  logo: {
    width: '100%',
    maxHeight: '70px',
    objectFit: 'contain',
    height: 'auto',
  },
  appButton: {
    width: 'auto',
    height: '30px',
  },
  subText: {
    padding: `0 12% 0`,
    marginBottom: '20px',
  },
  lineSeparatorContainer: {
    marginTop: '20px',
    marginBottom: '30px',
    padding: '0 2%',
  },
  copyRight: (theme: Theme) => ({
    [theme.breakpoints.down('md')]: {
      height: '150px',
      padding: '0 20% 0',
    },
  }),
};

export default styles;
