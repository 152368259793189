import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Theme } from '@mui/material';

function Copyright() {
  const year = new Date().getFullYear();
  return (
    <Typography
      sx={copyrightStyle}
      variant="body2"
      style={{ color: '#fff' }}
      align="center"
    >
      <Link color="inherit" href="/">
        <b>©CURRENCYBIRD {year} </b>, Ramón Ricardo Rosas 663, Puerto Varas, Chile
      </Link>
    </Typography>
  );
}
const copyrightStyle = (theme: Theme) => ({
  Copyright: {
    '& p': {
      fontWeight: `normal`,
    },
    [theme.breakpoints.down(769)]: {
      fontSize: `15px`,
      padding: `0 20% 0`,
    },
  },
});

export default Copyright;
