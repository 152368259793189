import { Grid } from '@mui/material';
import Hidden from '../Common/Hidden';
import {
  StyledA,
  StyledDiv,
  StyledFooter,
  StyledGatsbyLink,
  StyledH3,
  StyledH4,
  StyledImg,
} from '../Styled';
import AppStore from './../../images/appstorewhite.png';
import LogoBird from './../../images/cb_footer_icon.png';
import CCIcon from './../../images/ccbclogo.png';
import FintecLogo from './../../images/fintelogo.png';
import Gplay from './../../images/gplaywhite.png';
import IconFacebook from './../../images/icon_facebook.svg';
import IconInstagram from './../../images/icon_instagram.svg';
import IconLinkedIn from './../../images/icon_linkedin.svg';
import IconTwitter from './../../images/icon_twitter.svg';
import IconYoutube from './../../images/youtube_white.svg';
import Copyright from './Copyright';
import styles from './Footer.styles';

export default function StickyFooter() {
  return (
    <StyledFooter sx={styles.footer}>
      <Grid container sx={styles.container}>
        <Grid
          item
          container
          xs={12}
          justifyContent={'center'}
          alignItems={'flex-start'}
          columnSpacing={8}
          rowSpacing={{ xs: 8, md: 0 }}
          marginBottom={{ xs: '100px', md: '40px' }}
        >
          <Hidden direction="up" size="md">
            <Grid item id="boxLogoBird">
              <StyledImg sx={styles.icon} src={LogoBird} alt="LogoBird" />
            </Grid>
          </Hidden>
          <Grid item container rowSpacing={{ xs: 2, md: 0.5 }} xs={12} md={3}>
            <Grid item xs={12}>
              <StyledH3 sx={[styles.h3, { textWrap: 'noWrap' }]}>Empresa y equipo</StyledH3>
            </Grid>
            <Grid item xs={12}>
              <StyledGatsbyLink sx={styles.link} to="/nosotros">
                Nosotros
              </StyledGatsbyLink>
            </Grid>
            <Grid item xs={12}>
              <StyledGatsbyLink sx={styles.link} to="/">
                Personas
              </StyledGatsbyLink>
            </Grid>
            <Grid item xs={12}>
              <StyledGatsbyLink sx={styles.link} to="/empresas">
                Empresas
              </StyledGatsbyLink>
            </Grid>
          </Grid>
          <Grid item container rowSpacing={{ xs: 2, md: 0.5 }} xs={12} md={3}>
            <Grid item xs={12}>
              <StyledH3 sx={styles.h3}>Ayuda</StyledH3>
            </Grid>
            <Grid item xs={12}>
              <StyledA
                sx={styles.link}
                target="_blank"
                href="https://help.currencybird.cl/es/knowledge"
              >
                Centro de ayuda
              </StyledA>
            </Grid>
            <Grid item xs={12}>
              <StyledA sx={styles.link} target="_blank" href="https://blog.currencybird.cl/">
                Blog y novedades
              </StyledA>
            </Grid>
          </Grid>
          <Grid item container rowSpacing={{ xs: 2, md: 0.5 }} xs={12} md={3}>
            <Grid item xs={12}>
              <StyledH3 sx={styles.h3}>Legal</StyledH3>
            </Grid>
            <Grid item xs={12}>
              <StyledGatsbyLink sx={styles.link} to="/terms/">
                Términos y condiciones
              </StyledGatsbyLink>
            </Grid>
            <Grid item xs={12}>
              <StyledGatsbyLink sx={styles.link} to="/privacypolicy/">
                Política de privacidad
              </StyledGatsbyLink>
            </Grid>
            <Grid item xs={12}>
              <StyledA
                sx={styles.link}
                target="_blank"
                href="https://www.uaf.cl/legislacion/nuestra_ley.aspx"
              >
                Ley 19.913
              </StyledA>
            </Grid>
            <Grid item xs={12}>
              <StyledA
                sx={styles.link}
                target="_blank"
                href="https://www.bcn.cl/leychile/navegar?idNorma=1008668"
              >
                Ley 20.393
              </StyledA>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          container
          xs={12}
          justifyContent={'space-evenly'}
          alignItems={'center'}
          marginBottom={{ xs: '50px', md: '0' }}
          padding={{ xs: '0 20%', md: '0' }}
          rowSpacing={5}
        >
          <Grid item container xs={12} md={6} alignItems={'center'} spacing={2}>
            <Hidden direction="down" size="md">
              <Grid item md={4}>
                <StyledImg
                  src={LogoBird}
                  alt="LogoBird"
                  sx={{ ...styles.logo, paddingBottom: '20px' }}
                />
              </Grid>
            </Hidden>
            <Grid item xs={7} md={4}>
              <StyledA target="_blank" href="https://www.fintechile.org/">
                <StyledImg src={FintecLogo} alt="Fintec Logo" sx={styles.logo} />
              </StyledA>
            </Grid>
            <Grid item xs={5} md={4}>
              <StyledA target="_blank" href="https://camarachilenobrasilena.cl/">
                <StyledImg src={CCIcon} alt="CCBC Logo" sx={styles.logo} />
              </StyledA>
            </Grid>
          </Grid>
          <Grid item sx={styles.iconGroup} xs={12} md={2}>
            <StyledA target="_blank" href="https://www.facebook.com/currencybird.cl/">
              <StyledImg src={IconFacebook} alt="IconFacebook" />
            </StyledA>
            <StyledA target="_blank" href="https://twitter.com/currency_bird?lang=es">
              <StyledImg src={IconTwitter} alt="IconTwitter" />
            </StyledA>
            <StyledA target="_blank" href="https://www.youtube.com/@currencybird/videos">
              <StyledImg src={IconYoutube} alt="IconYoutube" />
            </StyledA>
            <StyledA
              target="_blank"
              href="https://www.linkedin.com/company/currencybird?originalSubdomain=cl"
            >
              <StyledImg src={IconLinkedIn} alt="SocialGroup" />
            </StyledA>
            <StyledA target="_blank" href="https://www.instagram.com/currencybird">
              <StyledImg src={IconInstagram} alt="IconInstagram" />
            </StyledA>
          </Grid>
          <Grid item container xs={12} sm={9} md={3} textAlign={'center'}>
            <Grid item xs={6}>
              <StyledA
                target="_blank"
                href="https://apps.apple.com/us/app/currencybird-env%C3%ADos-de-dinero/id6503993314"
                onClick={() => {
                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({
                    event: 'buttonClick',
                    category: 'Footer',
                    action: 'appstore_redirect',
                    label: 'Descargar App Store',
                  });
                }}
              >
                <StyledImg src={AppStore} alt="Appstore Logo" style={styles.appButton} />
              </StyledA>
            </Grid>
            <Grid item xs={6}>
              <StyledA
                target="_blank"
                href="https://play.google.com/store/apps/details?id=cl.currencybird.cbmobileapp"
                onClick={() => {
                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({
                    event: 'buttonClick',
                    category: 'Footer',
                    action: 'playstore_redirect',
                    label: 'Descargar Play Store',
                  });
                }}
              >
                <StyledImg src={Gplay} alt="Gplay Logo" style={styles.appButton} />
              </StyledA>
            </Grid>
          </Grid>
        </Grid>

        <Hidden direction="down" size="md">
          <Grid item xs={12} sx={styles.lineSeparatorContainer}>
            <StyledDiv sx={styles.lineSeparator}></StyledDiv>
          </Grid>
        </Hidden>

        <Grid item sx={styles.subText} xs={12}>
          <StyledH4 sx={styles.h4}>
            CurrencyBird SpA es una empresa registrada ante la Unidad de Análisis Financiero UAF y
            regida bajo la Ley Orgánica del Banco Central de Chile.
          </StyledH4>
        </Grid>

        <Grid item xs={12} sx={styles.copyRight}>
          <Copyright />
        </Grid>
      </Grid>
    </StyledFooter>
  );
}
