import Button from '@mui/material/Button';
import { Link } from 'gatsby';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import { Grid, AppBar, Toolbar, Avatar, Container } from '@mui/material';
import NavbarLinks from './NavbarLinks';
import NavItemMobile from './NavItemMobile';
import logo from '../../images/logo_navbar.svg';
import styles from './Navbar.styles';
import { useAuth } from '../../hooks/useAuth';
import { StyledDiv } from '../Styled';
import { useState } from 'react';

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    // extends React's HTMLAttributes
    open?: boolean;
  }
}

const NavBar = () => {
  const [open, setOpen] = useState<boolean>(false);
  const { getUserFromStorage, logout } = useAuth();
  const user = getUserFromStorage();

  const handleDrawerToggle = () => {
    setOpen((prev) => !prev);
  };

  return (
    <StyledDiv sx={styles.row}>
      <AppBar position="fixed" color="default" sx={styles.AppBar}>
        <Container sx={styles.container} maxWidth="lg" component="div" disableGutters>
          <Toolbar sx={styles.toolbar} disableGutters>
            <Grid sx={styles.toolbarSection}>
              <Avatar
                src={logo}
                alt="Logo"
                to="/"
                component={Link}
                sx={styles.avatar}
                sizes="cover"
              />
            </Grid>

            <Grid sx={[styles.toolbarSection, styles.linksContainer]}>
              <NavbarLinks />
            </Grid>

            <Grid sx={styles.toolbarSection}>
              <Button
                id="ButtonStart"
                color="inherit"
                sx={styles.registerButton}
                onClick={() => {
                  if (user && !user.completedProfile) {
                    logout();
                  } else {
                    window.location.href = `${process.env.GATSBY_URL_NEW_FRONT}/login`;
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                      event: 'buttonClick',
                      category: 'NavBar',
                      action: 'Click en boton Ingresar',
                      label: 'Barra de navegación, click en Ingresar',
                    });
                  }
                }}
              >
                {user && !user.completedProfile ? 'Cerrar sesión' : 'Ingresar'}
              </Button>
            </Grid>

            <IconButton
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerToggle}
              sx={styles.menuButon}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </Container>
        {open && (
          <Grid>
            <Grid sx={styles.secondarymenu}>
              <NavItemMobile setOpen={setOpen} />
            </Grid>
          </Grid>
        )}
      </AppBar>
    </StyledDiv>
  );
};

export default NavBar;
