import { styled } from '@mui/material/styles';

export const StyledH1 = styled('h1')({});
export const StyledH2 = styled('h2')({});
export const StyledH3 = styled('h3')({});
export const StyledH4 = styled('h4')({});

export const StyledP = styled('p')({});
export const StyledSpan = styled('span')({});
export const StyledA = styled('a')({});
