import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { navigate } from 'gatsby';
import React, { ReactNode } from 'react';
import { AuthProvider } from '../../src/components/AuthProvider';
import Footer from '../../src/components/Footer/Footer';
import Header from '../../src/components/Header/Header';
import theme from '../../src/theme';

type LayoutProps = {
  children: ReactNode;
  location: {
    pathname: string;
  };
};

const TopLayout: React.FC<LayoutProps> = ({ location, children }) => {
  const currentPath = location?.pathname || '';
  const isConverterPage = currentPath === '/converter/';

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider navigate={navigate}>
        {!isConverterPage && <Header />}
        {children}
        {!isConverterPage && <Footer />}
      </AuthProvider>
    </ThemeProvider>
  );
};

export default TopLayout;
