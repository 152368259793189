import React from 'react';
import jwt_decode from 'jwt-decode';
import { AuthContext, IAuthContext } from '../components/AuthProvider';

export interface IUseAuth {
  user: string | null;
  login: (
    authResult: AuthResult,
    shouldStoreResult: boolean,
    successful?: boolean
  ) => Promise<boolean>;
  logout: () => void;
  getUserFromStorage: () => User | null;
  isAuthenticated: () => boolean;
  getBearer: () => string | null;
}
export type TokenUser = { uuid: string; email: string; flags: number };
export type User = {
  uuid: string;
  email: string;
  flags: number;
  completedProfile: boolean;
};
export type AuthAction = {
  user: string | null;
  authResult: AuthResult | null;
  expiresIn: number | null;
};

export type AuthResult = {
  accessToken: string;
  type: string;
  expiresIn: number;
};

/**
 *
 * @param expiresIn number of ms to expiry date
 * @returns true if token is expired
 */
export const isValid = (expiresIn: number): boolean =>
  expiresIn ? new Date().getTime() < expiresIn * 1000 : false;

export function useAuth(): IUseAuth {
  const { authState, navigate, setAuthState } = React.useContext<IAuthContext>(AuthContext);

  /**
   * Called in resolve login promise
   */
  async function login(
    authResult: AuthResult,
    shouldStoreResult: boolean,
    successful: boolean = false
  ) {
    const result = await handleAuthResult({
      setAuthState,
      authResult,
      shouldStoreResult,
    });
    const user = getUserFromStorage();
    if (user && result) {
      if (user.completedProfile) {
        const memoryLink = localStorage.getItem('link');
        localStorage.setItem('link', '');
        if (memoryLink == 'referral') {
          window.location.replace(
            `${process.env.GATSBY_SITE_URL}/external-login/index?token=${authResult.accessToken}&view=referral`
          );
        } else {
          window.location.replace(
            `${process.env.GATSBY_SITE_URL}/external-login/index?token=${authResult.accessToken}`
          );
        }
        return true;
      } else if (!user.completedProfile && !successful) {
        navigate('/signup-form/');
        return true;
      } else if (successful) {
        navigate('/emailvalidated/');
        return true;
      }
    }
    return false;
  }

  function getUserFromStorage() {
    if (typeof window !== 'undefined') {
      const authResult = localStorage.getItem('bearer');
      const parsedAuthResult = (): AuthResult => (authResult ? JSON.parse(authResult) : null);
      const tokenUser = parsedAuthResult() && jwt_decode<TokenUser>(parsedAuthResult().accessToken);
      const completedProfile = tokenUser && tokenUser.flags === 1;

      if (tokenUser) return { ...tokenUser, completedProfile };
    }
    return null;
  }

  function logout() {
    // remove from local storage and state
    setAuthState({
      user: null,
      authResult: null,
      expiresIn: null,
    });
    localStorage.removeItem('bearer');
    navigate('/');
  }

  function isAuthenticated() {
    if (typeof window !== 'undefined') {
      const authResult = localStorage.getItem('bearer');
      const parsedAuthResult = (): AuthResult => (authResult ? JSON.parse(authResult) : null);
      const localExpiresIn = parsedAuthResult() && parsedAuthResult().expiresIn;
      if (localExpiresIn) {
        return true;
      }
    }
    return false;
  }

  function getBearer() {
    if (typeof window !== 'undefined') {
      const authResult = localStorage.getItem('bearer');
      const parsedAuthResult = (): AuthResult => (authResult ? JSON.parse(authResult) : null);
      return `Bearer ${parsedAuthResult().accessToken}`;
    }
    return null;
  }

  return {
    user: authState.user,
    isAuthenticated,
    login,
    logout,
    getBearer,
    getUserFromStorage,
  };
}

export async function handleAuthResult({
  setAuthState,
  authResult,
}: {
  shouldStoreResult?: boolean;
  setAuthState: React.Dispatch<AuthAction>;
  authResult: AuthResult;
}): Promise<boolean> {
  if (authResult && authResult.accessToken && authResult.type) {
    // TODO: if flags == 1 -> update exṕiresIn
    const decodedToken = jwt_decode<{ email: string; flags: number }>(authResult.accessToken);

    setAuthState({
      user: decodedToken.email,
      authResult: authResult,
      expiresIn: authResult.expiresIn,
    });

    localStorage.setItem('bearer', JSON.stringify(authResult));
    return true;
  } else {
    setAuthState({
      user: null,
      authResult: null,
      expiresIn: null,
    });
    localStorage.removeItem('bearer');
    return false;
  }
}
